
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.account-main-info {
  padding: 32px;
  display: flex;
  justify-content: space-between;

  @include for-size(phone-portrait-down) {
    padding: 20px;
    border-radius: 12px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }

  &__inner {
    display: flex;

    @include for-size(phone-landscape-down) {
      flex-direction: column;
    }
  }

  &__block {

    &--first {
      min-width: 225px;
      margin-right: 24px;

      @include for-size(phone-landscape-down) {
        margin-right: 0;
        margin-bottom: 24px;
      }
      @include for-size(phone-portrait-down) {
        margin-bottom: 16px;
        min-width: unset;
      }
    }
    &--second {
      max-width: 535px;
    }
  }

  &__name {
    font-size: 28px;
    line-height: 32px;
    color: $color-white;
    margin-bottom: 24px;

    @include for-size(phone-portrait-down) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  &__block-title {
    color: $color-white-op-75;
    margin-bottom: 8px;
  }

  &__block-caption {
    @include for-size(phone-portrait-down) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__avatar {
    .account-avatar__image {
      @include for-size(phone-portrait-down) {
        width: 72px !important;
        height: 72px !important;
        font-size: 18px !important;
      }
    }
  }
}
